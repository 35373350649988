import React from "react";

import "./style.scss";

const Footer = () => {
  return (
    <div className="footer no-select">
      <p className="copyright">Copyright 2020</p>
      <p className="company">Space Dysco</p>
      <a className="me" href="https://linkedin.com/in/afturner">
        Andrew F. Turner
      </a>
    </div>
  );
};

export default Footer;
