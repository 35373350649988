export default [
  [
    "Welcome!",
    "",
    "My name is Andrew, and I am the founder of *Dysco*.",
    "",
    "I have a *vision* for the future of computing. This is both a dream and a plan, it's a conviction and a faith in something better.",
    "",
    "I imagine many differences in a world where this vision is reality.",
    "",
    "Computers are built, and the operating system developed, with and *by the community* that uses them. The right to corroborated facts and *established truths* is universal and the tools of the trade are at reach for all people. Creators and businesses can sell and distribute *without losing fees* to a middle man. Any unique content can be universally identified and attributed to the creator. There is *no ambiguity about who someone is, or what the facts are.* Anything that can be contained in a social network, library, catalogue, encyclopedia, or other directory is authoritatively defined. Every user has a *single unique profile* to identify them. *Tracking and advertisements are always opt-in,* and they benefit the user as well as the advertiser. Users have complete control over all features, settings, and layout. ",
    "",
    "There are many more ideas that I wish to share, and I detail some of them when you join the mailing list, but the complete vision is not mature yet. In time, a fully encompassing plan will be revealed.",
    "",
    "Let it be clear, the near insurmountable challenge of these goals is not lost on me, despite the evidence to the contrary. *Space Dysco* doesn't make revenue, has a single unpaid employee that works part time, and the finish line is distant. The proposed vision would require new research and be comprised of complex projects not attempted by companies making less than $1B in revenue. This is why the vision is not the first step for *Dysco*.",
    "",
    "There is a roadmap comprised of several smaller applications and services which will come first. These products will make revenue by being better than the competition and/or fulfilling an unmet demand, while also serving as research and test grounds for long-term features. They will grow a loyal and outspoken user base that advocates for *Dysco*, building credibility in the business and refining the corporate strategy. Over time, *Dysco* will cultivate a diverse team and build up a codebase that will serve as the foundation for long-term projects. ",
    "",
    "One final important factor to point out is that our ambitions would lead us to a position of great power, and I take this very seriously. *Space Dysco* will be meticulously and exhaustively designed to only act in the interests of its users. This means careful corporate structuring, long-term planning and safeguarding, legal accountability, and community governance. This also means, unlike a traditional startup, *Space Dysco* will commit to never being sold to another company, no matter the offer. As a part of the fundamental bylaws, *Dysco* will be forthright and conscientiously transparent in all matters, and will exercise due diligence when it comes to the security and privacy of its users and systems. *Space Dysco* commits to goodwill and honest attempts to rectify mistakes and remedy side effects.",
    "",
    "So I hope that in the course of this letter, you have learned a little bit about the purpose and roadmap of *Dysco*. I am actively seeking part-time employees; there are details lower on this page.",
    "",
    "I encourage you to reach out with questions, comments, or criticisms. Nothing can happen without a vocal and involved user base. ",
    "",
    "Rock on,"
  ],
  ["*Andrew Federico Turner*", "Founder of Space Dysco"]
];
