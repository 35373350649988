import React from "react";

const Fingerprint2 = require("fingerprintjs2");

const start = (win, doc, ls, murmur) => {
  const isDnt =
    parseInt(navigator.doNotTrack) === 1 ||
    parseInt(win.doNotTrack) === 1 ||
    parseInt(navigator.msDoNotTrack) === 1 ||
    navigator.doNotTrack === "yes";

  const execute = () => {
    if (!isDnt) {
      ls.setItem("clientId", murmur);

      (function(i, s, o, g, r, a, m) {
        i["GoogleAnalyticsObject"] = r;
        (i[r] =
          i[r] ||
          function() {
            (i[r].q = i[r].q || []).push(arguments);
          }),
          (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(
        win,
        doc,
        "script",
        "https://www.google-analytics.com/analytics.js",
        "ga"
      );

      if (typeof ga === "function") {
        console.log(
          `Your client identifier is: ${murmur}. It will be stored in LS.`
        );
        ga("create", "UA-157980096-1", "none", {
          storeGac: false,
          storage: "none",
          clientId: murmur
        });
        ga("set", "anonymizeIp", true);
        ga("set", "allowAdFeatures", "false");
        ga("send", "pageview");
      }
    } else {
      // TODO: write a super simple page hit script
      console.log("Detected DNT! Won't track visit.");
    }
  };

  if (win.requestIdleCallback) {
    requestIdleCallback(execute);
  } else {
    setTimeout(execute, 500);
  }
};

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.excludes = {
      hardwareConcurrency: true,
      plugins: true,
      canvas: true,
      adBlock: true,
      webgl: true,
      enumerateDevices: true,
      platform: true,
      cpuClass: true,
      doNotTrack: true
    };
  }

  componentDidMount() {
    const [win, doc, ls] = [window, document, localStorage];
    const data = process.env.NODE_ENV || "production";
    const existingFingerprint = localStorage.getItem("clientId");
    // if (data === "production") {
    if (existingFingerprint) {
      start(win, doc, ls, existingFingerprint);
    } else {
      Fingerprint2.get({ excludes: this.excludes }, components => {
        var values = components.map(function(component) {
          return component.value;
        });
        var murmur = Fingerprint2.x64hash128(values.join(""), 31);
        start(win, doc, ls, murmur);
      });
    }
    // }
  }

  render() {
    return <></>;
  }
}
