import React from "react";

import "./style.scss";

class MailingList extends React.Component {
  constructor(props) {
    super(props);
    this.pressed = false;
  }

  handleClick() {
    this.pressed = true;
    const redirectUrl =
      "https://cdn.forms-content.sg-form.com/ec18ac06-40b3-11ea-8b07-f25f8293185d";
    const redirect = (from = "No GA") => {
      console.log(`${from}: Redirecting...`);
      window.location.assign(redirectUrl);
    };
    if (typeof ga === "function") {
      ga("send", {
        hitType: "event",
        eventCategory: "Landing Page Actions",
        eventAction: "click",
        eventLabel: "Tap to join mailing list",
        hitCallback: () => redirect("GA")
      });
    } else {
      redirect();
    }
  }

  render() {
    return (
      <div
        className="mailing-list no-select"
        tabIndex="0"
        role="button"
        aria-pressed={`${this.pressed}`}
        onClick={this.handleClick.bind(this)}
        onKeyDown={({ keyCode = -1 }) => {
          if (keyCode === 13) this.handleClick();
        }}
      >
        <h2 className="mailing-list-text">Tap me to join the mailing list!</h2>
      </div>
    );
  }
}

export default MailingList;
