import React from "react";

import Gradient from "../shared/gradient";

import "./style.scss";

const Heading = () => {
  const backgroundGradient = {
    colors: [
      { value: "#232323", position: "-30%" },
      { value: "#000000", position: "60%" }
    ],
    height: "100%",
    width: "100%",
    radius: 0,
    rotation: "-187deg"
  };

  return (
    <>
      <div className="dysco-heading">
        <Gradient {...backgroundGradient} />
      </div>
      <h1 className="title no-select">Space Dysco</h1>
    </>
  );
};

export default Heading;
