import React from "react";

import "./style.scss";
import Gradient from "../../shared/gradient";

export default () => (
  <div className="project-container project-os-container">
    <Gradient
      {...{
        rotation: "160deg",
        colors: [{ value: "#010A0A", position: "10%" }, { value: "#00070F" }],
        height: "100%",
        width: "100%"
      }}
    >
      <div className="project-inner-container">
        <h2 className="project-name no-select">O.S.</h2>
        <h3 className="project-tagline">
          {" "}
          A omni system for every device, a semantic web, and the abolishment of
          the web browser.
        </h3>
      </div>
    </Gradient>
  </div>
);
