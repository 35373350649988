import React from "react";

import Text from "./text";
import Gradient from "../shared/gradient";
import { textIntoHTML } from "../../utils/TextUtils";
import Signature from "./signature";

import "./style.scss";

const [IntroText, SignatureText] = Text;

class Intro extends React.Component {
  constructor(props) {
    super(props);

    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.expanded = false;
    this.hasClicked = false;
    this.recalculate = () => {
      let text = [].concat(IntroText);
      let sigText = [].concat(SignatureText);
      if (!this.expanded) {
        const addAfterI = text.indexOf(
          "My name is Andrew, and I am the founder of *Dysco*."
        );
        text.splice(addAfterI + 1, text.length - addAfterI + 1);
      }
      this.introText = <div role="article">{textIntoHTML(text)}</div>;
      this.sigText = textIntoHTML(sigText);
      this.introExpandText = this.expanded ? (
        <>
          <p className="dots">&#8963;</p>
          <p className="text">Tap to see less</p>
        </>
      ) : (
        <>
          <p className="dots">...</p>
          <p className="text">Tap to see more</p>
        </>
      );
      this.introGradient = Object.assign(
        {
          height: "100%",
          radius: 21,
          rotation: this.expanded ? "-180deg" : "-200deg"
        },
        this.expanded
          ? {
              colors: [
                { value: "#131313", position: "10%" },
                { value: "#00070C", position: "70%" }
              ]
            }
          : {
              colors: [
                { value: "#131313", position: "0%" },
                { value: "#010101", position: "35%" },
                { value: "#00070C", position: "95%" }
              ]
            }
      );
    };

    this.recalculate();
  }

  forceUpdateHandler() {
    this.expanded = !this.expanded;
    if (!this.hasClicked) {
      this.hasClicked = true;
      if (typeof ga === "function") {
        ga("send", {
          hitType: "event",
          eventCategory: "Landing Page Actions",
          eventAction: "expand",
          eventLabel: "Intro Text"
        });
      }
    }
    this.recalculate();
    this.forceUpdate();
    if (this.expanded === false) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className={`intro-container${this.expanded ? " expanded" : ""}`}>
        <Gradient className="introGradient" {...this.introGradient}>
          <div className="introText">{this.introText}</div>
          <Signature />
          <div className="sigText">{this.expanded ? this.sigText : <></>}</div>
          <div
            className="introExpand no-select"
            tabIndex="0"
            role="button"
            aria-pressed={`${this.expanded}`}
            onClick={this.forceUpdateHandler}
            onKeyDown={({ keyCode = -1 }) => {
              if (keyCode === 13) this.forceUpdateHandler();
            }}
          >
            {this.introExpandText}
          </div>
        </Gradient>
      </div>
    );
  }
}

export default Intro;
