import React from "react";

import "./style.scss";
import Gradient from "../../shared/gradient";

export default () => (
  <div className="project-container project-orion-container">
    <Gradient
      {...{
        rotation: "160deg",
        colors: [{ value: "#013D04", position: "10%" }, { value: "#001624" }],
        height: "100%",
        width: "100%"
      }}
    >
      <div className="project-inner-container">
        <h2 className="project-name no-select">ORION</h2>
        <h3 className="project-tagline">
          Smart, anonymous metasearch.
          <br />
          <br />
          Find, organize, and permanently store the whole world. Never lose
          anything again.
        </h3>
      </div>
    </Gradient>
  </div>
);
