import React from "react";

import "./style.scss";
import Gradient from "../../shared/gradient";

export default () => (
  <div className="project-container project-platinumpass-container">
    <Gradient
      {...{
        rotation: "160deg",
        colors: [{ value: "#470000", position: "10%" }, { value: "#012437" }],
        height: "100%",
        width: "100%"
      }}
    >
      <div className="project-inner-container">
        <h2 className="project-name no-select">Platinum Pass</h2>
        <h3 className="project-tagline">
          One account to rule them all.{" "}
          <span role="img" aria-label="diamond">
            💎
          </span>
          <br />
          <br />
          Being <i>you</i> comes with its benefits.
        </h3>
      </div>
    </Gradient>
  </div>
);
