import React from "react";
import { Helmet } from "react-helmet";

import "./style.scss";

// Analytics
import Report from "../../utils/report";

// Components
import Heading from "../Heading";
import Intro from "../Intro";
import MailingList from "../MailingList";
import PlannedProjects from "../PlannedProjects";
import CoreValues from "../CoreValues";
import CTA from "../CTA";
import Footer from "../Footer";

import { withPrefix } from "gatsby";

const Main = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Space Dysco - Welcome</title>
        <meta
          name="Description"
          content="My name is Andrew, and I am the founder of Dysco. I have a vision to design an elegant and universal generational leap in computing."
        />
        <link rel="canonical" href="https://www.dysco.space/" />
        <html lang="en" />
        <script
          src={withPrefix("focus-visible.min.js")}
          type="text/javascript"
        />
      </Helmet>
      <div className="container">
        <Report />
        <Heading />
        <Intro />
        <MailingList />
        <PlannedProjects />
        <CoreValues />
        <CTA />
        <Footer />
      </div>
    </>
  );
};

export default Main;
