import React from "react";

import "./style.scss";

const CoreValues = () => {
  return (
    <div className="core-values">
      <h1 className="section-header no-select">Core Values</h1>
      <h2 className="core-values-disclaimer">
        <i>
          I will do my best to meet the following values but may miss things
          until there are more people in the company to help. Feel free to get
          in touch with concerns or questions.
        </i>
      </h2>
      <div className="core-value-sections">
        <div className="core-value-section transparency-section">
          <h3 className="core-values-subheader no-select">Transparency</h3>
          <p className="core-values-description">
            One of the primary tenets of Dysco is to genuinely be as transparent
            as possible. Before the first app is released, a clear plan for
            transparency will published. GitLab is a good basic example.
          </p>
        </div>
        <div className="core-value-section community-section">
          <h3 className="core-values-subheader no-select">Community</h3>
          <p className="core-values-description">
            Community will play a large role in the future of Dysco. When a
            critical user mass is reached Dysco will introduce community
            governance and crowdsourced feature requests, trivial to critical
            bug bounties, and PRs.
          </p>
        </div>
        <div className="break" />
        <div className="core-value-section ux-section">
          <h3 className="core-values-subheader no-select">UX</h3>
          <p className="core-values-description">
            I believe that user experience is the most important factor in short
            and long term user satisfaction. I also believe that user
            satisfaction is the most important factor in determining the success
            of a company. Therefore, UX will be the primary focus in every
            decision.
            <br />
            <br />A comprehensive list will be published when Dysco has a CEO,
            but UX will encompass many facets including design, accessibility,
            ease of migrating data when joining/leaving Dysco products, support
            options, and audited security.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
