export default [
  "Looking for a minimum commitment of 10 hours per week for staff.",
  "",
  "These are *part-time, pro-bono positions.* You will be required to submit a timesheet. Once the company is making revenue, employees will be retroactively paid.",
  "",
  "*“Why would I work for free?”* Only if you believe in the plan that will take Dysco to revenue. We will *sign a legal contract* before you work that will guarantee payment for the agreed upon wage as soon as the company is making revenue. This will be as soon as the completion of a crowdsourcing campaign for Quotie.",
  "",
  "People of *all* backgrounds are encouraged to get in touch.",
  "",
  "Your unique perspective and input will play a big part in shaping the plan, vision, and future of *Space Dysco.*",
  "",
  "Seeking:",
  "- CEO",
  "- Advisors",
  "- iOS developers",
  "- Web developers",
  "- Designers",
  "- Business, marking, or finance experience",
  "- Bring other knowledge or experience to the table? Let me know.",
  "",
  "^universe@dysco.space^"
];
