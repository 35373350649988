import React from "react";
const uuid = require("uuid/v4");
const getUUID = () => uuid().split("-")[0];

const makeToken = (type, children) => {
  switch (type) {
    case "italic":
      return <i key={getUUID()}>{children}</i>;
    case "bold":
      return <b key={getUUID()}>{children}</b>;
    case "centered":
      return <center key={getUUID()}>{children}</center>;
    default:
      throw new Error(`Unknown replacement type: ${type}`);
  }
};

const tokenizeText = (text, token, type) => {
  if (typeof text === "object") {
    // console.log(text.props.children)
    return text;
  } else if (text.indexOf(token) > -1) {
    let words = text.split(token);
    let tokenCount = words.length - 1;
    let matchCount = tokenCount / 2;
    if (tokenCount % 2 === 0) {
      for (var i = 0; i < matchCount; i += 1) {
        let iOfInnerMatch = (i + 1) * 2 - 1;
        words[iOfInnerMatch] = makeToken(type, words[iOfInnerMatch]);
      }
      return words.filter(val => `${val}`.indexOf(token) === -1);
    } else {
      throw new Error(`Unmatched ${token} symbols!`);
    }
  }
  return text;
};

/**
 *
 * @param {string[]} text
 */
const textIntoHTML = (text = []) => {
  return text.reduce((arr, line, idx) => {
    if (!line) {
      arr.push(<br key={getUUID()} />);
      return arr;
    }
    [
      { token: "_", name: "italic" },
      { token: "*", name: "bold" },
      { token: "^", name: "centered" }
    ].forEach(({ token, name }) => {
      if (!Array.isArray(line)) {
        line = tokenizeText(line, token, name);
      } else {
        line = line
          .map(segment => tokenizeText(segment, token, name))
          .reduce((arr, itm) => {
            if (Array.isArray(itm)) {
              arr = arr.concat(itm);
            } else {
              arr.push(itm);
            }
            return arr;
          }, []);
      }
    });
    if (Array.isArray(line)) {
      arr = arr.concat(line).concat(<br key={getUUID()} />);
    } else {
      arr.push(
        <div key={getUUID()}>
          <span key={getUUID()}>{line}</span>
          <br key={getUUID()} />
        </div>
      );
    }
    return arr;
  }, []);
};

export { textIntoHTML };
