import React from "react";

import "./style.scss";
import Gradient from "../../shared/gradient";

export default () => (
  <div className="project-container project-quotie-container">
    <Gradient
      {...{
        colors: [{ value: "#0035FF" }, { value: "#1D0E3A" }],
        height: "100%",
        width: "7.34375rem",
        margin: "0 auto"
      }}
    >
      <div className="project-inner-container">
        <h2 className="project-name no-select">Quotie</h2>
        <h3 className="project-tagline">
          elegant quote collection and discovery
        </h3>
      </div>
    </Gradient>
    <p className="quotie-disclaimer">
      *There is currently no specific launch date, but I am actively working on
      Quotie.
    </p>
  </div>
);
