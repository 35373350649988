import React from "react";

import "./style.scss";
import Gradient from "../../shared/gradient";

export default () => (
  <div className="project-container project-becky-container">
    <Gradient
      {...{
        rotation: "160deg",
        colors: [{ value: "#FFFFFF", position: "10%" }, { value: "#D49B9B" }],
        height: "100%",
        width: "100%"
      }}
    >
      <div className="project-inner-container">
        <h2 className="project-name no-select">Becky</h2>
        <h3 className="project-tagline">
          A dynamic, domain-based portal for hyperlocal communities. Starting in
          college campuses.
        </h3>
      </div>
    </Gradient>
  </div>
);
