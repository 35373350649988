import React from "react";

import ProjectHeader from "./ProjectHeader/index";
import Quotie from "./Quotie/index";
import PlatinumPass from "./PlatinumPass/index";
import Dovetail from "./Dovetail/index";
import Becky from "./Becky/index";
import Orion from "./Orion/index";
import OS from "./OS/index";

import "./style.scss";

const PlannedProjects = () => {
  return (
    <div className="planned-projects">
      <h1 className="section-header no-select">Planned Projects</h1>
      <ProjectHeader text="Active Development" />
      <Quotie />
      <ProjectHeader text="Research and Design" />
      <div className="collapsing">
        <PlatinumPass />
        <Dovetail />
        <div className="break" />
        <Becky />
        <Orion />
      </div>
      <ProjectHeader text="Future Projects" />
      <OS />
    </div>
  );
};

export default PlannedProjects;
