import React from "react";

import "./style.scss";
import Gradient from "../../shared/gradient";

export default () => (
  <div className="project-container project-dovetail-container">
    <Gradient
      {...{
        rotation: "160deg",
        colors: [{ value: "#FFFFFF", position: "10%" }, { value: "#FFB800" }],
        height: "100%",
        width: "100%"
      }}
    >
      <div className="project-inner-container">
        <h2 className="project-name no-select">Dovetail</h2>
        <h3 className="project-tagline">
          From auditions to the stage, a complete digital solution for companies
          and performers.
        </h3>
      </div>
    </Gradient>
  </div>
);
