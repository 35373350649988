import React from "react";

import Gradient from "../shared/gradient";
import CTAText from "./text";
import { textIntoHTML } from "../../utils/TextUtils";

import "./style.scss";

const CTA = () => {
  const ctaText = textIntoHTML(CTAText);
  const ctaGradient = {
    colors: [
      { value: "#011532", position: "0%" },
      { value: "#000415", position: "60%" }
    ],
    rotation: "-180deg",
    radius: 21
  };
  return (
    <>
      <div className="cta-container">
        <Gradient {...ctaGradient}>
          <div className="cta-inner-container">
            <h2 className="cta-title no-select">Help make Dysco</h2>
            <div className="cta-text">{ctaText}</div>
          </div>
        </Gradient>
      </div>
    </>
  );
};

export default CTA;
