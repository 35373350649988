import React from "react";

import "normalize.css";

const makeGradient = ({ rotation = null, colors }) => {
  let rotationStr = "";
  if (rotation !== null) {
    rotationStr = `${rotation}, `;
  }
  let colorStr = "";
  colors.forEach(({ value, position = null }) => {
    if (position !== null) {
      value = `${value} ${position}, `;
    } else {
      value = `${value}, `;
    }
    colorStr = `${colorStr}${value}`;
  });
  colorStr = colorStr.substring(0, colorStr.length - 2);
  return `linear-gradient(${rotationStr}${colorStr})`;
};

const GradientObject = ({
  rotation,
  colors,
  height,
  width,
  children,
  radius = "0.84375rem",
  margin,
  style,
  className
}) => (
  <div
    className={className}
    style={Object.assign({}, style, {
      background: makeGradient({ rotation, colors }),
      borderRadius: radius,
      width: typeof width === "string" ? width : `${width}px`,
      height: typeof height === "string" ? height : `${height}px`,
      margin
    })}
  >
    {children}
  </div>
);

export default args => <GradientObject {...args} />;
