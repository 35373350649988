// Styles
import "normalize.css";
import "../styles/reset.css";
import "../styles/fonts.css";
import "../styles/main.scss";

// Page
import Main from "../components/Main";

export default Main;
